module.exports = [{
      plugin: require('/builds/denningk/eatdrinkrva/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Lato:400,700","Roboto:100,400,500,900"]}},
    },{
      plugin: require('/builds/denningk/eatdrinkrva/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145414926-1"},
    },{
      plugin: require('/builds/denningk/eatdrinkrva/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://eatdrinkrva.com"},
    },{
      plugin: require('/builds/denningk/eatdrinkrva/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/denningk/eatdrinkrva/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
